/* Custom styles for the date input placeholder */
input[type="date"]:before {
    content: attr(placeholder);
    color: #aaa;
  }
  
  input[type="date"]:focus:before,
  input[type="date"]:valid:before {
    content: "";
  }
  
.schedule-meetDate {
  margin-right: 14px;
}