/* Base styles for the target element */
.tooltip-target {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

/* Styles for the tooltip */
.tooltip-target::after {
    content: attr(data-tooltip); /* Use the data-tooltip attribute for the tooltip text */
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 125%; /* Position above the element */
    left: 50%;
    margin-left: -60px; /* Center the tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

/* Tooltip arrow */
.tooltip-target::before {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s;
}

/* Show the tooltip when hovering over the target element */
.tooltip-target:hover::after,
.tooltip-target:hover::before {
    visibility: visible;
    opacity: 1;
}

app-uitoolkit{
    width: 100% !important;
}

.controlskit{ 
    height: auto !important;
}

.controlskit .controls{
    display: block;
    flex-wrap: wrap !important;
}
.controlskit .controls button{
  position: relative;
  margin: 1rem;
}